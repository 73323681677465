import React from "react";
import Header from "../Header/Header";
import IntroDesktop from "./IntroDesktop";
import LogoBelt from "./LogoBelt";
import Impact from "./Impact";
import WhyChoose from "./WhyChoose";
import TargetAudience from "./TargetAudience";
import "./styles/SpringBootPage.css";
import CourseCurriculum from "./CourseCurriculum";
import ProjectSection from "./ProjectSection";
import Mentor from "./Mentor";
import SuccessStories from "../CppLandingPage/SuccessStories";
import Pricing from "../CppLandingPage/Pricing/Pricing";
import Faq from "./Faq";
import ExploreCourses from "../ExploreCourses/ExploreCourses";
import { availableCourses } from "../../courses/CourseConstants";
import ExploreBlogs from "../ExploreBlogs/ExploreBlogs";
import FreeTrial from "../Common/CommonComponents/FreeTrial";
import Footer from "../Footer/Footer";
import Outcomes from "./Outcomes";

function SpringBootLandingPage() {
    return (
        <div className="springboot-page">
            <div className="header-sticky">
                <Header />
            </div>
            <IntroDesktop />
            <LogoBelt />
            <Impact />
            <WhyChoose />
            <TargetAudience />
            <Outcomes />
            <CourseCurriculum />
            <ProjectSection />
            <Mentor />
            <SuccessStories />
            <Pricing />
            <Faq />
            <ExploreCourses course={availableCourses.CRASH_COURSE} />
            <ExploreBlogs course={availableCourses.CRASH_COURSE} />
            <FreeTrial />
            <Footer />
        </div>
    );
}

export default SpringBootLandingPage;
