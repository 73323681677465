import mixpanel from "mixpanel-browser";
import "./App.css";
import "./fonts.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { LandingPageRoute } from "./components/LandingPageRoute/LandingPageRoute";
import TermsAndConditionRoute from "./components/TermsAndCondition/TermsAndConditionRoute/TermsAndConditionRoute";
import RefundPolicyRoute from "./components/RefundPolicy/RefundPolicyRoute.js/RefundPolicyRoute";
import { Login } from "./components/LoginFlow/Login/Login";
import { SignUp } from "./components/LoginFlow/SignUp/SignUp";
import { ForgotPassword } from "./components/LoginFlow/ForgotPassword/ForgotPassword";
import LoginFlow from "./components/LoginFlow/LoginFlow";
import { SignUpWithGoogle } from "./components/LoginFlow/GoogleLogin/GoogleLoginScreen";
import ScrollToTop from "./ScrollToTop";
import Stories from "./components/Stories/Stories";
import { StoriesContextProvider } from "./Contexts/StoriesContexts";
import { useGlobalContext } from "./Contexts/GlobalContext";
import { StartFreeTrial } from "./components/StartFreeTrial/StartFreeTrial";
import { mixpanelToken } from "./mixpanelConstant";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { isNULLEMPTYORUNDEFINED } from "./Utilities/SessionUtility";
import { SubscriptionContextProvider } from "./components/LandingPage/Pricing/SubscriptionContext";
import { PricingContextProvider } from "./Contexts/PricingContext";
import SubscriptionRenewal from "./components/LandingPage/Pricing/SubscriptionRenewal";
import CourseSelectionScreen from "./courses/SelectionScreen/CourseSelectionScreen";
import LowLevelDesignLandingPage from "./components/LowLevelDesignLandingPage/LowLevelDesignLandingPage";
import WelcomeScreenFreeTrial from "./components/WelcomeScreen/WelcomeScreenFreeTrial";
import CppLandingPageRoute from "./components/CppLandingPage/CppLandingPageRoute";
import ReactLandingPageRoute from "./components/ReactLandingPage/ReactLandingPageRoute";
import DsaCrashCourseRoute from "./components/DsaCrashCourse/DsaCrashCourseRoute";
import PythonLandingPageRoute from "./components/PythonLandingPage/PythonLandingPageRoute";
import { initializeGTM } from "./GoogleAnaytics/gtmManager";
import RepublicDayBanner from "./components/Header/RepublicDayBanner";
import HoliBanner from "./components/Header/HoliBanner";
import T20wcBanner from "./components/Header/T20wcBanner";
import SpringBootLandingPage from "./components/SpringBootLandingPage/SpringBootLandingPage";
mixpanel.init(mixpanelToken, { ignore_dnt: true });

function App() {
    const cookies = new Cookies();
    const [loaded, setLoaded] = useState(false);
    const { checkSubscribed } = useGlobalContext();

    useEffect(() => {
        initializeGTM();
        getAuthCookie();
    }, []);

    const getAuthCookie = async () => {
        try {
            if (!isNULLEMPTYORUNDEFINED(cookies.get("authorization"))) await checkSubscribed();
        } catch (err) {
            console.error("Error fetching subscription data : " + err);
        } finally {
            setLoaded(true);
        }
    };

    return !loaded ? (
        <></>
    ) : (
        <div className="App Success-Stories-App">
            <Router>
                {/* to scroll to the top of the screen  */}
                <ScrollToTop />

                {/* <RepublicDayBanner /> */}
                {/* <HoliBanner /> */}
                {/* <T20wcBanner /> */}

                <Routes>
                    <Route
                        exact
                        path="/crack-coding-interviews"
                        element={
                            <SubscriptionContextProvider>
                                <LandingPageRoute />
                            </SubscriptionContextProvider>
                        }
                    />
                    <Route
                        exact
                        path="/terms-and-conditions"
                        element={<TermsAndConditionRoute />}
                    />
                    <Route exact path="/refund-policy" element={<RefundPolicyRoute />} />
                    <Route
                        exact
                        path="/crack-coding-interviews/stories"
                        element={
                            <StoriesContextProvider>
                                <Stories />
                            </StoriesContextProvider>
                        }
                    />
                    <Route
                        exact
                        path="/stories"
                        element={<Navigate to="/crack-coding-interviews/stories" replace />}
                    />
                    <Route path="*" element={<Navigate to="/crack-coding-interviews" replace />} />
                    <Route exact path="/" element={<LoginFlow />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/signup-with-google" element={<SignUpWithGoogle />} />
                    <Route path="/start-free-trial" element={<StartFreeTrial />} />

                    <Route
                        exact
                        path="/low-level-design"
                        element={
                            <PricingContextProvider>
                                <LowLevelDesignLandingPage />
                            </PricingContextProvider>
                        }
                    />
                    <Route
                        exact
                        path="/dsa-and-algorithms-essentials"
                        element={
                            <PricingContextProvider>
                                <DsaCrashCourseRoute />
                            </PricingContextProvider>
                        }
                    />
                    <Route
                        exact
                        path="/become-a-frontend-engineer"
                        element={
                            <PricingContextProvider>
                                <ReactLandingPageRoute />
                            </PricingContextProvider>
                        }
                    />
                    <Route
                        exact
                        path="/begin-with-c++"
                        element={
                            <PricingContextProvider>
                                <CppLandingPageRoute />
                            </PricingContextProvider>
                        }
                    />

                    <Route
                        exact
                        path="/begin-with-python"
                        element={
                            <PricingContextProvider>
                                <PythonLandingPageRoute />{" "}
                            </PricingContextProvider>
                        }
                    />

                    <Route exact path="/course-select" element={<CourseSelectionScreen />} />

                    <Route
                        exact
                        path="/java-springboot"
                        element={
                            <PricingContextProvider>
                                <SpringBootLandingPage />
                            </PricingContextProvider>
                        }
                    />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
