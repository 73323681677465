import React from "react";
import { useEffect } from "react";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/SessionUtility";
import Cookies from "universal-cookie";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import CommonIntro from "../Common/CommonComponents/CommonIntro";
import CommonAbout from "../Common/CommonComponents/CommonAbout";
import CommonCourseFor from "../Common/CommonComponents/CommonCourseFor";
import CommonOutcomes from "../Common/CommonComponents/CommonOutcomes";
import CommonPrerequisites from "../Common/CommonComponents/CommonPrerequisites";
import CommonCurriculum from "../Common/CommonComponents/CommonCurriculum";
import CommonInstructors from "../Common/CommonComponents/CommonInstructors";
import CommonLearners from "../Common/CommonComponents/CommonLearners";
import CommonFaqs from "../Common/CommonComponents/CommonFaqs";
import CommonPricing from "../Common/CommonPricing/CommonPricing";
import CommonIntroVideo from "../Common/CommonComponents/CommonIntroVideo";
import { ReactIntroBannerContents } from "./ReactSectionContents/ReactIntroContents";
import { ReactAboutContents } from "./ReactSectionContents/ReactAboutContents";
import { ReactCourseForContents } from "./ReactSectionContents/ReactCourseForContents";
import { ReactOutcomeContents } from "./ReactSectionContents/ReactOutcomeContents";
import { ReactPrerequisitesContents } from "./ReactSectionContents/ReactPrerequisitesContent";
import ExploreCourses from "../ExploreCourses/ExploreCourses";
import {
    instructorsSectionTitle,
    ReactInstructorsList,
} from "./ReactSectionContents/ReactInstructorsContent";
import { Reacttopics } from "./ReactSectionContents/ReactCurriculumContent";
import { studentsInfo } from "../Common/CommonLearnersContent";
import { ReactPricingContents } from "./ReactSectionContents/ReactPricingContent";
import { ReactFaqContents } from "./ReactSectionContents/ReactFaqContent";
import { setCurrentCourse } from "../../courses/CourseUtility";
import { availableCourses } from "../../courses/CourseConstants";
import CommonExploreCourse from "../Common/CommonComponents/CommonExploreCourses";
import { setUTMParametersInLocalStorage, useQuery } from "../../Utilities/CommonUtilities";

const ReactLandingPageRoute = () => {
    setCurrentCourse(availableCourses.REACT);
    const cookies = new Cookies();
    const { checkSubscribed } = useGlobalContext();
    useEffect(() => {
        if (!isNULLEMPTYORUNDEFINED(cookies.get("authorization"))) checkSubscribed();
    }, []);

    const query = useQuery();
    setUTMParametersInLocalStorage(query);

    return (
        <div>
            <div className="header-sticky">
                <Header />
            </div>
            <CommonIntro IntroBannerContents={ReactIntroBannerContents} />
            <div id="About">
                <CommonAbout aboutContents={ReactAboutContents} />
            </div>
            <div>{/* intro video part <CommonIntroVideo/> */}</div>
            <div id="Who this course is for">
                <CommonCourseFor courseForContents={ReactCourseForContents} />
            </div>

            <div id="Learning Outcomes">
                <CommonOutcomes outcomeContents={ReactOutcomeContents} />
            </div>
            <div id="Prerequisites">
                <CommonPrerequisites prerequisitesContent={ReactPrerequisitesContents} />
            </div>
            <div id="Course Curriculum">
                <CommonCurriculum topics={Reacttopics} />
            </div>
            <div id="Instructor">
                <CommonInstructors
                    InstructorsList={ReactInstructorsList}
                    instructorsSectionTitle={instructorsSectionTitle}
                />
            </div>
            <CommonLearners studentsInfo={studentsInfo} />
            <div id="Pricing">{<CommonPricing pricingContent={ReactPricingContents} />}</div>

            <div id="explore-courses">
                <ExploreCourses course={availableCourses.PYTHON} />
            </div>

            <div id="FAQs">
                <CommonFaqs faqsContent={ReactFaqContents} />
            </div>

            <Footer />
        </div>
    );
};

export default ReactLandingPageRoute;
