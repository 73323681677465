import React from "react";
import { useEffect } from "react";
import Header from "../Header/Header";
import FreeTrial from "./FreeTrial";

import { setCurrentCourse } from "../../courses/CourseUtility";
import { availableCourses } from "../../courses/CourseConstants";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import Cookies from "universal-cookie";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/SessionUtility";
import Intro from "./Intro";
import About from "./About";
import Audience from "./Audience";
import Advantages from "./Advantages";
import Curriculum from "./Curriculum";
import Faq from "./Faq";
import ExploreCourses from "../ExploreCourses/ExploreCourses";
import ExploreBlogs from "../ExploreBlogs/ExploreBlogs";
import Prerequisites from "./Prerequisites";
import Mentor from "../Mentor/Mentor";
import Mentors from "./Mentors";
import Pricing from "./Pricing/Pricing";
import SuccessStories from "./SuccessStories";
// import Footer from "./Footer";
import Footer from "../Footer/Footer";
import { setUTMParametersInLocalStorage, useQuery } from "../../Utilities/CommonUtilities";
import NodeJsEventBanner from "../Header/NodeJsEventBanner";
import SpringBanner from "../Header/SpringBanner";

const LowLevelDesignLandingPage = () => {
    setCurrentCourse(availableCourses.LLD);

    const cookies = new Cookies();
    const { checkSubscribed } = useGlobalContext();
    useEffect(() => {
        if (!isNULLEMPTYORUNDEFINED(cookies.get("authorization"))) checkSubscribed();
    }, []);

    const query = useQuery();
    setUTMParametersInLocalStorage(query);

    return (
        <div>
            {/* <div>
                <SpringBanner />
            </div> */}
            <div className="header-sticky">
                <Header />
            </div>
            <div>
                <Intro />
            </div>
            <div>
                <About />
            </div>
            <div>
                <Audience />
            </div>
            <div>
                <Advantages />
            </div>
            <div>
                <Curriculum />
            </div>
            <div>
                <Prerequisites />
            </div>
            <div>
                <Mentor course={availableCourses.LLD} />
            </div>

            <div>
                <SuccessStories />
            </div>
            <div>
                <Pricing />
            </div>
            <div>
                <Faq />
            </div>
            <div>
                <ExploreCourses course={availableCourses.LLD}/>
            </div>
            <div>
                <ExploreBlogs course={availableCourses.LLD}/>
            </div>

            <div>
                <FreeTrial />
            </div>

            <div>
                <Footer />
            </div>
        </div>
    );
};

export default LowLevelDesignLandingPage;
