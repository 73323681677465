import React from "react";
import "../ComponentsStyles/CommonPricing.css";
import { useNavigate, useLocation } from "react-router-dom";

import { useState, useEffect } from "react";
import swal from "sweetalert";
import gift from "../../../img/gift.svg";

import {
    getPlans,
    updatePlans,
    implementInitiatePayments,
    placeOrderAndShowRz,
} from "./PricingClient";
import hoorayImg from "../../../img/hoorayImg.png";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import { CircularProgress } from "@mui/material";
import useWindowDimensions from "../../../hooks/UseWindowDimensions";
import ActionButton from "../ActionButton";
import PricingPlanCard from "./PricingPlanCard";
import { usePricingContext } from "../../../Contexts/PricingContext";
import { sendEventToGTM } from "../../../GoogleAnaytics/gtmManager";
import { getCurrentCourse } from "../../../courses/CourseUtility";
import { GTM_TRACKER } from "../../../GoogleAnaytics/gtmConstants";
import { gaCourseNameMap } from "../../../courses/CourseConstants";

const CommonPricing = (props) => {
    const { pricingContent } = props;
    const style = {
        color: "white",
        background: " linear-gradient(91.59deg, #444BAB 1.85%, #3CA5C2 99.69%)",
        fontFamily: "Poppins-SemiBold",
        textTransform: "capitalize",
        padding: "10px 16px",
        width: "20.9vw",
        height: "3.8vw",
        fontSize: "1.39vw",
    };
    const mobileStyle = {
        color: "white",
        background: " linear-gradient(91.59deg, #444BAB 1.85%, #3CA5C2 99.69%)",
        fontFamily: "Poppins-SemiBold",
        textTransform: "capitalize",
        padding: "10px 16px",
        width: "300px",
        height: "50px",
        fontSize: "20px",
        marginTop: "10px",
    };
    const { windowWidth } = useWindowDimensions();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        setUpgradeCouponCode,
        setReferralOrDiscountCouponCode,
        referralOrDiscountCouponCode,
        upgradeCouponCode,
        setLoaded,
        loaded,
        setErrorMessage,
        errorMessage,
    } = usePricingContext();

    const {
        loggedIn,
        onFreeTrial,
        subscribed,
        currentLoggedInUserEmail,
        couponDiscountPrice,
        everSubscribed,
    } = useGlobalContext();

    const [plans, setPlans] = useState([]);
    const [couponPlans, setCouponPlans] = useState([]);
    const [isCouponValid, setIsCouponValid] = useState(null);
    const [couponCode, setCouponCode] = useState("");

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [orderId, setOrderId] = useState("");
    const [key, setKey] = useState("");
    const [amount, setAmount] = useState(0);
    const [currency, setCurrency] = useState(0);
    const [razorpay_payment_id, setRazorpayPaymentsId] = useState("");
    const [showSavedMoney, setShowSavedMoney] = useState(false);

    const [errorSnackbar, setErrorSnackBar] = useState(false);
    const [paymentErrorModal, setPaymentErrorModal] = useState(false);

    useEffect(() => {
        getPlans(setPlans, getErrorDisplayModal, setLoaded);
    }, [location]);

    const setPaymentDetails = (orderId, key, amount, currency, razorpay_payment_id) => {
        setOrderId(orderId);
        setKey(key);
        setAmount(amount);
        setCurrency(currency);
        setRazorpayPaymentsId(razorpay_payment_id);
    };

    const setPaymentsId = async (razorpay_payment_id, metadata) => {
        sendEventToGTM({
            event: "successful-payment",
            course: `${gaCourseNameMap[getCurrentCourse()]}`,
            amount: metadata?.amount,
            currency: metadata?.currency
        });
        await swal("Done", "Payment Successful.", "success");
        setRazorpayPaymentsId(razorpay_payment_id);
    };

    const initiatePayment = (subscriptionDetails) => {
        try {
            let data;
            let { subscription_id } = subscriptionDetails;
            data = {
                subscriptionID: subscription_id,
                username: currentLoggedInUserEmail,
                referralOrDiscountCode: referralOrDiscountCouponCode,
            };
            implementInitiatePayments(
                data,
                setPaymentDetails,
                placeOrderAndShowRz,
                currentLoggedInUserEmail,
                setPaymentsId,
                setLoaded,
                setPaymentErrorModal,
                setErrorMessage
            );
        } catch (error) {}
    };

    const handleUserInput = (event) => {
        setCouponCode(event.target.value);
    };
    const handleCouponSubmit = (event) => {
        event.preventDefault();
        if (couponCode) {
            setReferralOrDiscountCouponCode(couponCode);
            setErrorSnackBar(false);
            updatePlans(
                couponCode,
                setReferralOrDiscountCouponCode,
                setCouponPlans,
                setIsCouponValid,
                setIsModalVisible,
                setShowSavedMoney,
                setLoaded,
                setErrorSnackBar
            );
        }
    };

    const getErrorDisplayModal = (errorMessage) => {
        return (
            <section onClick={() => setPaymentErrorModal(false)} className="modal-wrapper-bg">
                <div className="error-message-card-wrapper">
                    <div className="close-mark">x</div>
                    <p>{errorMessage}</p>
                </div>
            </section>
        );
    };

    const handleRemove = () => {
        setIsCouponValid(null);
        setUpgradeCouponCode("");
        setReferralOrDiscountCouponCode("");
        setCouponCode("");
        setErrorSnackBar(false);
        setShowSavedMoney(false);
    };

    const handleRemoveCouponCode = () => {
        setTimeout(() => {
            handleRemove();
        }, 1500);
    };

    const geterrorPopUp = () => {
        return (
            <>
                <div className="error-coupon-code-wrapper">
                    <p>This Coupon Code is invalid. Please try again.</p>
                </div>
                {handleRemoveCouponCode()}
            </>
        );
    };

    const getOnSuccessOfCouponCodeModal = (couponDiscountPrice) => {
        return (
            <section onClick={() => setIsModalVisible(false)} className="modal-wrapper-bg">
                <div className="success-modal-wrapper">
                    <img src={hoorayImg} alt="" />
                    <h3>'{couponCode}' applied </h3>
                    <h4>You have saved upto ₹ {couponDiscountPrice} </h4>
                    <p>with this coupon code</p>
                    <button onClick={() => setIsModalVisible(false)}>Thanks!</button>
                </div>
            </section>
        );
    };

    const getSubscriptionPlansSection = () => {
        let subscriptionPlans = isCouponValid ? couponPlans : plans;

        return (
            <div>
                {paymentErrorModal && getErrorDisplayModal(errorMessage)}
                {loaded ? (
                    <div className="subscription-loader-wrapper">
                        <CircularProgress />
                    </div>
                ) : (
                    <div>
                        <PricingPlanCard
                            subscriptionPlans={subscriptionPlans}
                            couponCode={couponCode}
                            isCouponValid={isCouponValid}
                            showSavedMoney={showSavedMoney}
                            initiatePayment={initiatePayment}
                            loggedIn={loggedIn}
                            checkUserSubscribed={subscribed}
                            checkOnFreeTrail={onFreeTrial}
                            everSubscribed={everSubscribed}
                        />
                    </div>
                )}
            </div>
        );
    };

    const getCouponCodeSection = () => {
        return (
            <div>
                <p className="emi-text">
                    Note: Easy EMI options available with HDFC Cards and Zest Money.
                </p>
                <div className="referral-code">
                    {isCouponValid && isModalVisible ? (
                        getOnSuccessOfCouponCodeModal(couponDiscountPrice)
                    ) : (
                        <></>
                    )}
                    <div className="gift-wrapper">
                        <img src={gift} alt=""></img>
                        <p>I have a Referral Code/Coupon Code!</p>
                    </div>
                    <input
                        value={couponCode}
                        onChange={handleUserInput}
                        type="text"
                        placeholder="Enter Code"
                    />

                    {isCouponValid ? (
                        <span onClick={handleRemove}>Remove</span>
                    ) : (
                        <span onClick={handleCouponSubmit}>Apply</span>
                    )}
                </div>
            </div>
        );
    };
    const getFreeTrialSection = () => {
        return (
            <div className="free-trial-wrapper">
                <p>Make Your Own Success Story!</p>
                <span>Start Your 7 Days Free Trial Now!</span>
                <div
                    className={GTM_TRACKER.START_FREE_TRIAL}
                    onClick={
                        () => {
                            navigate("/signup");
                        }
                        // window.open(`${process.env.REACT_APP_FRONTEND_MAIN_URL}signup`, "_self")
                    }
                >
                    <ActionButton
                        style={windowWidth > 600 ? style : mobileStyle}
                        name=" Start 7 Day Free Trial"
                        action="“Start Free Trial” below pricing"
                    />
                </div>
            </div>
        );
    };
    return (
        <>
            <div className="common-pricing-wrapper">
                <h1>{pricingContent.sectionTitle}</h1>
                {getSubscriptionPlansSection()}
                {getCouponCodeSection()}
            </div>
            {errorSnackbar ? geterrorPopUp() : null}
            {!loggedIn && getFreeTrialSection()}
        </>
    );
};

export default CommonPricing;
